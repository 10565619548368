import { Component } from '@angular/core';
import { AuthService } from './api/auth/auth.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'competition';
  toggleMenu: boolean = false;

  loggedInUser: any;
  loadingUser: boolean;
  emailVerified: boolean;

  constructor(
    public snackBar: MatSnackBar,
    public authData: AuthService,
    private router: Router
  ) {
    authData.currentUser().subscribe((data) => {
      this.loadingUser = true;
      if(data) {
        this.emailVerified = data.emailVerified;
        console.log(data);
        this.loggedInUser = data;
        this.router.navigate(this.emailVerified ? ['/profile'] : ['/login']);
        this.loadingUser = false;
        if(!data) {
         this.router.navigate(['/login']);
          this.loggedInUser = null;
          this.loadingUser = false;
        }
      }
      else {
       this.router.navigate(['/login']);
        this.loggedInUser = null;
        this.loadingUser = false;
      }
    });
  }

  logout() {
    this.authData.logoutUser().then(() => {
     this.router.navigate(['/login']);
      this.snackBar.open('You have been logged out', 'CLOSE', {
        duration: 2000,
      });
      this.loggedInUser = null;
    });
  }
}
