import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  usersDB: AngularFirestoreCollection<any> = this.afs.collection('users');

  constructor(
    public afAuth: AngularFireAuth,
    public afd: AngularFireDatabase,
    private afs: AngularFirestore,
    public snackBar: MatSnackBar
    ) {}

  loginUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(newEmail, newPassword);
  }

  getLoggedInUser(id) {
    let userDetails = this.afs.collection('users', ref => ref.where('uid', '==', id));
    return userDetails;
  }

  resetPassword(email: string): Promise<any> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<any> {
    return this.afAuth.auth.signOut();
  }

  signupUser(userData): Promise<any> {

    return this.afAuth.auth.createUserAndRetrieveDataWithEmailAndPassword(userData.email, userData.password).then((data) =>{
      this.afAuth.auth.currentUser.sendEmailVerification().then(function() {
        // Email sent.
        // UIkit.notification({ message: 'verification email has been sent to ' + userData.email, pos: 'bottom-center' });
        // this.snackBar.open(`verification email has been sent to ${userData.email}`, 'CLOSE', { duration: 3000 });
       }, function(error) { console.log('An error happened'); });

      this.afAuth.auth.currentUser.updateProfile({ displayName: userData.firstName, photoURL:"null" }).then(() => {
        this.usersDB.add({
          firstName: userData.firstName,
          lastName: userData.lastName,
          uid: data.user.uid,
          cellNumber: userData.cellNumber,
          email: data.user.email,
          userType: 'SUBSCRIBER'
        }).then(res => {
          res.update({ id: res.id,  }).then(response => {
          });
        })
      })
    });
  }

  currentUser(): any {
    return this.afAuth.authState
  }
}
