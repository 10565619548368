import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AuthService } from 'src/app/api/auth/auth.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/api/users/users.service';
import { CompetitionsService } from 'src/app/api/competitions/competitions.service';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { EnterCompetitionComponent } from '../competition/enter-competition/enter-competition.component';
import { AddWorkComponent } from '../competition/add-work/add-work.component';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userData: any;
  imageUrl: any;
  buttonLabel: string;
  competitionsData: any;
  userWorkData: any[];
  loadingWorkData: boolean = true;
  toggleEdit: boolean = false;
  form: FormGroup;

  constructor(
    public snackBar: MatSnackBar,
    public authProvider: AuthService,
    public usersProvider: UsersService,
    public competitionsProvider: CompetitionsService,
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      bio: [this.userData ? this.userData.bio : null, Validators.required],
    });
  }

  ngOnInit() {
    this.buttonLabel = 'Upload image';
    this.authProvider.currentUser().subscribe((data) => {
      this.authProvider.getLoggedInUser(data.uid).valueChanges().subscribe((data)=> {
        this.userData = data[0];
        this.getUserWork(this.userData.id);
        this.form.patchValue({bio: this.userData.bio ? this.userData.bio : '' });
      });
    });

    this.competitionsProvider.getCompetitions().valueChanges().subscribe((data)=> {
      this.competitionsData = data;
    });
  }

  getUserWork(id) {
    this.loadingWorkData = true;
    this.competitionsProvider.getUserWork(id).valueChanges().subscribe((data) => {
      this.userWorkData = data;
      this.loadingWorkData = false;
    })
  }

  saveBio() {
    this.userData.bio = this.form.value.bio;
    if(this.userData.id) {
      this.usersProvider.updateUser(this.userData).then(data => {
        this.toggleEdit = false;
      });
    }
  }

  onImageUploaded(url) {
    this.userData.imageUrl = url;
    this.usersProvider.updateUser(this.userData);
  }

  onIdUploaded(url) {
    this.userData.idDocument = url;
    this.usersProvider.updateUser(this.userData);
  }

  removeDocument() {
    this.userData.idDocument = null;
    this.usersProvider.updateUser(this.userData);
  }

  editProfile(item) {
    this.dialog.open(UpdateProfileComponent, {
      width: '600px',
      data: item
    });
  }

  enterCompetition(item) {
    this.dialog.open(EnterCompetitionComponent, {
      width: '600px',
      data: {competition: item, userData: this.userData}
    });
  }


  addWork() {
    this.dialog.open(AddWorkComponent, {
      width: '600px',
      data: {workData: null, userData: this.userData}
    });
  }



  editWork(item) {
    this.dialog.open(AddWorkComponent, {
      width: '600px',
      data: {workData: item, userData: this.userData}
    });
  }

  onDeleteWork(item) {
    this.competitionsProvider.deleteWork('work', item);
  }

}
