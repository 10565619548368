export const countriesData = [
    {
        "id": "1",
        "enabled": "1",
        "code3l": "AFG",
        "code2l": "AF",
        "name": "Afghanistan",
        "name_official": "Islamic Republic of Afghanistan",
        "flag_32": "AF-32.png",
        "flag_128": "AF-128.png",
        "latitude": "33.98299275",
        "longitude": "66.39159363",
        "zoom": "6"
    },
    {
        "id": "199",
        "enabled": "1",
        "code3l": "ALA",
        "code2l": "AX",
        "name": "\u00c5land Islands",
        "name_official": "\u00c5land Islands",
        "flag_32": "AX-32.png",
        "flag_128": "AX-128.png",
        "latitude": "60.25403213",
        "longitude": "20.35918350",
        "zoom": "9"
    },
    {
        "id": "2",
        "enabled": "1",
        "code3l": "ALB",
        "code2l": "AL",
        "name": "Albania",
        "name_official": "Republic of Albania",
        "flag_32": "AL-32.png",
        "flag_128": "AL-128.png",
        "latitude": "41.00017358",
        "longitude": "19.87170014",
        "zoom": "7"
    },
    {
        "id": "3",
        "enabled": "1",
        "code3l": "DZA",
        "code2l": "DZ",
        "name": "Algeria",
        "name_official": "People\u2019s Democratic Republic of Algeria",
        "flag_32": "DZ-32.png",
        "flag_128": "DZ-128.png",
        "latitude": "27.89861690",
        "longitude": "3.19771194",
        "zoom": "5"
    },
    {
        "id": "197",
        "enabled": "1",
        "code3l": "ASM",
        "code2l": "AS",
        "name": "American Samoa",
        "name_official": "The United States Territory of American Samoa",
        "flag_32": "AS-32.png",
        "flag_128": "AS-128.png",
        "latitude": "-14.30634641",
        "longitude": "-170.69501750",
        "zoom": "11"
    },
    {
        "id": "4",
        "enabled": "1",
        "code3l": "AND",
        "code2l": "AD",
        "name": "Andorra",
        "name_official": "Principality of Andorra",
        "flag_32": "AD-32.png",
        "flag_128": "AD-128.png",
        "latitude": "42.54057088",
        "longitude": "1.55201340",
        "zoom": "11"
    },
    {
        "id": "5",
        "enabled": "1",
        "code3l": "AGO",
        "code2l": "AO",
        "name": "Angola",
        "name_official": "Republic of Angola",
        "flag_32": "AO-32.png",
        "flag_128": "AO-128.png",
        "latitude": "-12.16469683",
        "longitude": "16.70933622",
        "zoom": "6"
    },
    {
        "id": "195",
        "enabled": "1",
        "code3l": "AIA",
        "code2l": "AI",
        "name": "Anguilla",
        "name_official": "Anguilla",
        "flag_32": "AI-32.png",
        "flag_128": "AI-128.png",
        "latitude": "18.22053521",
        "longitude": "-63.06861300",
        "zoom": "12"
    },
    {
        "id": "196",
        "enabled": "1",
        "code3l": "ATA",
        "code2l": "AQ",
        "name": "Antarctica",
        "name_official": "Antarctica",
        "flag_32": "AQ-32.png",
        "flag_128": "AQ-128.png",
        "latitude": "-45.13806295",
        "longitude": "10.48095703",
        "zoom": "2"
    },
    {
        "id": "6",
        "enabled": "1",
        "code3l": "ATG",
        "code2l": "AG",
        "name": "Antigua and Barbuda",
        "name_official": "Antigua and Barbuda",
        "flag_32": "AG-32.png",
        "flag_128": "AG-128.png",
        "latitude": "17.48060423",
        "longitude": "-61.42014426",
        "zoom": "9"
    },
    {
        "id": "7",
        "enabled": "1",
        "code3l": "ARG",
        "code2l": "AR",
        "name": "Argentina",
        "name_official": "Argentine Republic",
        "flag_32": "AR-32.png",
        "flag_128": "AR-128.png",
        "latitude": "-38.01529308",
        "longitude": "-64.97897469",
        "zoom": "4"
    },
    {
        "id": "8",
        "enabled": "1",
        "code3l": "ARM",
        "code2l": "AM",
        "name": "Armenia",
        "name_official": "Republic of Armenia",
        "flag_32": "AM-32.png",
        "flag_128": "AM-128.png",
        "latitude": "40.13475528",
        "longitude": "45.01072318",
        "zoom": "7"
    },
    {
        "id": "198",
        "enabled": "1",
        "code3l": "ABW",
        "code2l": "AW",
        "name": "Aruba",
        "name_official": "Aruba of the Kingdom of the Netherlands",
        "flag_32": "AW-32.png",
        "flag_128": "AW-128.png",
        "latitude": "12.52109661",
        "longitude": "-69.96833800",
        "zoom": "12"
    },
    {
        "id": "9",
        "enabled": "1",
        "code3l": "AUS",
        "code2l": "AU",
        "name": "Australia",
        "name_official": "Australia",
        "flag_32": "AU-32.png",
        "flag_128": "AU-128.png",
        "latitude": "-26.29594646",
        "longitude": "133.55540944",
        "zoom": "4"
    },
    {
        "id": "10",
        "enabled": "1",
        "code3l": "AUT",
        "code2l": "AT",
        "name": "Austria",
        "name_official": "Republic of Austria",
        "flag_32": "AT-32.png",
        "flag_128": "AT-128.png",
        "latitude": "47.63125476",
        "longitude": "13.18776731",
        "zoom": "7"
    },
    {
        "id": "11",
        "enabled": "1",
        "code3l": "AZE",
        "code2l": "AZ",
        "name": "Azerbaijan",
        "name_official": "Republic of Azerbaijan",
        "flag_32": "AZ-32.png",
        "flag_128": "AZ-128.png",
        "latitude": "40.35321757",
        "longitude": "47.46706372",
        "zoom": "7"
    },
    {
        "id": "12",
        "enabled": "1",
        "code3l": "BHS",
        "code2l": "BS",
        "name": "Bahamas",
        "name_official": "Commonwealth of the Bahamas",
        "flag_32": "BS-32.png",
        "flag_128": "BS-128.png",
        "latitude": "24.45991732",
        "longitude": "-77.68192453",
        "zoom": "7"
    },
    {
        "id": "13",
        "enabled": "1",
        "code3l": "BHR",
        "code2l": "BH",
        "name": "Bahrain",
        "name_official": "Kingdom of Bahrain",
        "flag_32": "BH-32.png",
        "flag_128": "BH-128.png",
        "latitude": "25.90740996",
        "longitude": "50.65932354",
        "zoom": "9"
    },
    {
        "id": "14",
        "enabled": "1",
        "code3l": "BGD",
        "code2l": "BD",
        "name": "Bangladesh",
        "name_official": "People\u2019s Republic of Bangladesh",
        "flag_32": "BD-32.png",
        "flag_128": "BD-128.png",
        "latitude": "24.08273251",
        "longitude": "90.49915527",
        "zoom": "7"
    },
    {
        "id": "15",
        "enabled": "1",
        "code3l": "BRB",
        "code2l": "BB",
        "name": "Barbados",
        "name_official": "Barbados",
        "flag_32": "BB-32.png",
        "flag_128": "BB-128.png",
        "latitude": "13.19383077",
        "longitude": "-59.54319600",
        "zoom": "11"
    },
    {
        "id": "16",
        "enabled": "1",
        "code3l": "BLR",
        "code2l": "BY",
        "name": "Belarus",
        "name_official": "Republic of Belarus",
        "flag_32": "BY-32.png",
        "flag_128": "BY-128.png",
        "latitude": "53.58628747",
        "longitude": "27.95338900",
        "zoom": "6"
    },
    {
        "id": "17",
        "enabled": "1",
        "code3l": "BEL",
        "code2l": "BE",
        "name": "Belgium",
        "name_official": "Kingdom of Belgium",
        "flag_32": "BE-32.png",
        "flag_128": "BE-128.png",
        "latitude": "50.49593874",
        "longitude": "4.46993600",
        "zoom": "8"
    },
    {
        "id": "18",
        "enabled": "1",
        "code3l": "BLZ",
        "code2l": "BZ",
        "name": "Belize",
        "name_official": "Belize",
        "flag_32": "BZ-32.png",
        "flag_128": "BZ-128.png",
        "latitude": "17.21153631",
        "longitude": "-88.01424956",
        "zoom": "8"
    },
    {
        "id": "19",
        "enabled": "1",
        "code3l": "BEN",
        "code2l": "BJ",
        "name": "Benin",
        "name_official": "Republic of Benin",
        "flag_32": "BJ-32.png",
        "flag_128": "BJ-128.png",
        "latitude": "9.37180859",
        "longitude": "2.29386134",
        "zoom": "7"
    },
    {
        "id": "201",
        "enabled": "1",
        "code3l": "BMU",
        "code2l": "BM",
        "name": "Bermuda",
        "name_official": "the Bermudas",
        "flag_32": "BM-32.png",
        "flag_128": "BM-128.png",
        "latitude": "32.31995785",
        "longitude": "-64.76182765",
        "zoom": "12"
    },
    {
        "id": "20",
        "enabled": "1",
        "code3l": "BTN",
        "code2l": "BT",
        "name": "Bhutan",
        "name_official": "Kingdom of Bhutan",
        "flag_32": "BT-32.png",
        "flag_128": "BT-128.png",
        "latitude": "27.50752756",
        "longitude": "90.43360300",
        "zoom": "8"
    },
    {
        "id": "182",
        "enabled": "1",
        "code3l": "BOL",
        "code2l": "BO",
        "name": "Bolivia",
        "name_official": "Plurinational State of Bolivia",
        "flag_32": "BO-32.png",
        "flag_128": "BO-128.png",
        "latitude": "-16.74518128",
        "longitude": "-65.19265691",
        "zoom": "6"
    },
    {
        "id": "202",
        "enabled": "1",
        "code3l": "BES",
        "code2l": "BQ",
        "name": "Bonaire, Saint Eustatius And Saba",
        "name_official": "Bonaire, Saint Eustatius and Saba",
        "flag_32": "BQ-32.png",
        "flag_128": "BQ-128.png",
        "latitude": "12.17229702",
        "longitude": "-68.28831170",
        "zoom": "11"
    },
    {
        "id": "21",
        "enabled": "1",
        "code3l": "BIH",
        "code2l": "BA",
        "name": "Bosnia and Herzegovina",
        "name_official": "Bosnia and Herzegovina",
        "flag_32": "BA-32.png",
        "flag_128": "BA-128.png",
        "latitude": "44.00040856",
        "longitude": "17.81640910",
        "zoom": "7"
    },
    {
        "id": "22",
        "enabled": "1",
        "code3l": "BWA",
        "code2l": "BW",
        "name": "Botswana",
        "name_official": "Republic of Botswana",
        "flag_32": "BW-32.png",
        "flag_128": "BW-128.png",
        "latitude": "-22.18279485",
        "longitude": "24.22344422",
        "zoom": "6"
    },
    {
        "id": "203",
        "enabled": "1",
        "code3l": "BVT",
        "code2l": "BV",
        "name": "Bouvet Island",
        "name_official": "Bouvet Island",
        "flag_32": "BV-32.png",
        "flag_128": "BV-128.png",
        "latitude": "-54.42316906",
        "longitude": "3.41319600",
        "zoom": "12"
    },
    {
        "id": "23",
        "enabled": "1",
        "code3l": "BRA",
        "code2l": "BR",
        "name": "Brazil",
        "name_official": "Federative Republic of Brazil",
        "flag_32": "BR-32.png",
        "flag_128": "BR-128.png",
        "latitude": "-11.80965046",
        "longitude": "-53.33152600",
        "zoom": "4"
    },
    {
        "id": "220",
        "enabled": "1",
        "code3l": "IOT",
        "code2l": "IO",
        "name": "British Indian Ocean Territory",
        "name_official": "The British Indian Ocean Territory",
        "flag_32": "IO-32.png",
        "flag_128": "IO-128.png",
        "latitude": "-7.33461519",
        "longitude": "72.42425280",
        "zoom": "12"
    },
    {
        "id": "24",
        "enabled": "1",
        "code3l": "BRN",
        "code2l": "BN",
        "name": "Brunei",
        "name_official": "Brunei Darussalam",
        "flag_32": "BN-32.png",
        "flag_128": "BN-128.png",
        "latitude": "4.54189364",
        "longitude": "114.60132823",
        "zoom": "9"
    },
    {
        "id": "25",
        "enabled": "1",
        "code3l": "BGR",
        "code2l": "BG",
        "name": "Bulgaria",
        "name_official": "Republic of Bulgaria",
        "flag_32": "BG-32.png",
        "flag_128": "BG-128.png",
        "latitude": "42.70160678",
        "longitude": "25.48583200",
        "zoom": "7"
    },
    {
        "id": "26",
        "enabled": "1",
        "code3l": "BFA",
        "code2l": "BF",
        "name": "Burkina Faso",
        "name_official": "Burkina Faso",
        "flag_32": "BF-32.png",
        "flag_128": "BF-128.png",
        "latitude": "12.22492458",
        "longitude": "-1.56159100",
        "zoom": "7"
    },
    {
        "id": "27",
        "enabled": "1",
        "code3l": "BDI",
        "code2l": "BI",
        "name": "Burundi",
        "name_official": "Republic of Burundi",
        "flag_32": "BI-32.png",
        "flag_128": "BI-128.png",
        "latitude": "-3.40499707",
        "longitude": "29.88592902",
        "zoom": "8"
    },
    {
        "id": "31",
        "enabled": "1",
        "code3l": "CPV",
        "code2l": "CV",
        "name": "Cabo Verde",
        "name_official": "Republic of Cabo Verde",
        "flag_32": "CV-32.png",
        "flag_128": "CV-128.png",
        "latitude": "15.11988711",
        "longitude": "-23.60517010",
        "zoom": "10"
    },
    {
        "id": "28",
        "enabled": "1",
        "code3l": "KHM",
        "code2l": "KH",
        "name": "Cambodia",
        "name_official": "Kingdom of Cambodia",
        "flag_32": "KH-32.png",
        "flag_128": "KH-128.png",
        "latitude": "12.83288883",
        "longitude": "104.84814273",
        "zoom": "7"
    },
    {
        "id": "29",
        "enabled": "1",
        "code3l": "CMR",
        "code2l": "CM",
        "name": "Cameroon",
        "name_official": "Republic of Cameroon",
        "flag_32": "CM-32.png",
        "flag_128": "CM-128.png",
        "latitude": "7.38622543",
        "longitude": "12.72825915",
        "zoom": "6"
    },
    {
        "id": "30",
        "enabled": "1",
        "code3l": "CAN",
        "code2l": "CA",
        "name": "Canada",
        "name_official": "Canada",
        "flag_32": "CA-32.png",
        "flag_128": "CA-128.png",
        "latitude": "60.36196817",
        "longitude": "-106.69833150",
        "zoom": "4"
    },
    {
        "id": "222",
        "enabled": "1",
        "code3l": "CYM",
        "code2l": "KY",
        "name": "Cayman Islands",
        "name_official": "The Cayman Islands",
        "flag_32": "KY-32.png",
        "flag_128": "KY-128.png",
        "latitude": "19.31322102",
        "longitude": "-81.25459800",
        "zoom": "11"
    },
    {
        "id": "32",
        "enabled": "1",
        "code3l": "CAF",
        "code2l": "CF",
        "name": "Central African Republic",
        "name_official": "Central African Republic",
        "flag_32": "CF-32.png",
        "flag_128": "CF-128.png",
        "latitude": "6.82541830",
        "longitude": "20.64281514",
        "zoom": "6"
    },
    {
        "id": "33",
        "enabled": "1",
        "code3l": "TCD",
        "code2l": "TD",
        "name": "Chad",
        "name_official": "Republic of Chad",
        "flag_32": "TD-32.png",
        "flag_128": "TD-128.png",
        "latitude": "14.80342407",
        "longitude": "18.78714064",
        "zoom": "5"
    },
    {
        "id": "34",
        "enabled": "1",
        "code3l": "CHL",
        "code2l": "CL",
        "name": "Chile",
        "name_official": "Republic of Chile",
        "flag_32": "CL-32.png",
        "flag_128": "CL-128.png",
        "latitude": "-38.01760790",
        "longitude": "-71.40014474",
        "zoom": "4"
    },
    {
        "id": "35",
        "enabled": "1",
        "code3l": "CHN",
        "code2l": "CN",
        "name": "China",
        "name_official": "People's Republic of China",
        "flag_32": "CN-32.png",
        "flag_128": "CN-128.png",
        "latitude": "36.71457440",
        "longitude": "103.55819197",
        "zoom": "4"
    },
    {
        "id": "206",
        "enabled": "1",
        "code3l": "CXR",
        "code2l": "CX",
        "name": "Christmas Island",
        "name_official": "Territory of Christmas Island",
        "flag_32": "CX-32.png",
        "flag_128": "CX-128.png",
        "latitude": "-10.49170619",
        "longitude": "105.68083796",
        "zoom": "11"
    },
    {
        "id": "204",
        "enabled": "1",
        "code3l": "CCK",
        "code2l": "CC",
        "name": "Cocos (Keeling) Islands",
        "name_official": "Territory of Cocos (Keeling) Islands",
        "flag_32": "CC-32.png",
        "flag_128": "CC-128.png",
        "latitude": "-12.12890685",
        "longitude": "96.84689104",
        "zoom": "12"
    },
    {
        "id": "36",
        "enabled": "1",
        "code3l": "COL",
        "code2l": "CO",
        "name": "Colombia",
        "name_official": "Republic of Colombia",
        "flag_32": "CO-32.png",
        "flag_128": "CO-128.png",
        "latitude": "3.68182320",
        "longitude": "-73.53927436",
        "zoom": "5"
    },
    {
        "id": "37",
        "enabled": "1",
        "code3l": "COM",
        "code2l": "KM",
        "name": "Comoros",
        "name_official": "Union of the Comoros",
        "flag_32": "KM-32.png",
        "flag_128": "KM-128.png",
        "latitude": "-11.64529989",
        "longitude": "43.33330200",
        "zoom": "10"
    },
    {
        "id": "38",
        "enabled": "1",
        "code3l": "COG",
        "code2l": "CG",
        "name": "Congo",
        "name_official": "Republic of the Congo",
        "flag_32": "CG-32.png",
        "flag_128": "CG-128.png",
        "latitude": "-0.68967806",
        "longitude": "15.69033190",
        "zoom": "6"
    },
    {
        "id": "181",
        "enabled": "1",
        "code3l": "COK",
        "code2l": "CK",
        "name": "Cook Islands",
        "name_official": "Cook Islands",
        "flag_32": "CK-32.png",
        "flag_128": "CK-128.png",
        "latitude": "-21.23673066",
        "longitude": "-159.77766900",
        "zoom": "13"
    },
    {
        "id": "39",
        "enabled": "1",
        "code3l": "CRI",
        "code2l": "CR",
        "name": "Costa Rica",
        "name_official": "Republic of Costa Rica",
        "flag_32": "CR-32.png",
        "flag_128": "CR-128.png",
        "latitude": "9.98427463",
        "longitude": "-84.09949534",
        "zoom": "8"
    },
    {
        "id": "44",
        "enabled": "1",
        "code3l": "CIV",
        "code2l": "CI",
        "name": "C\u00f4te d'Ivoire",
        "name_official": "Republic of C\u00f4te d'Ivoire",
        "flag_32": "CI-32.png",
        "flag_128": "CI-128.png",
        "latitude": "7.59684148",
        "longitude": "-5.49214636",
        "zoom": "7"
    },
    {
        "id": "40",
        "enabled": "1",
        "code3l": "HRV",
        "code2l": "HR",
        "name": "Croatia",
        "name_official": "Republic of Croatia",
        "flag_32": "HR-32.png",
        "flag_128": "HR-128.png",
        "latitude": "44.81372482",
        "longitude": "16.29039507",
        "zoom": "7"
    },
    {
        "id": "41",
        "enabled": "1",
        "code3l": "CUB",
        "code2l": "CU",
        "name": "Cuba",
        "name_official": "Republic of Cuba",
        "flag_32": "CU-32.png",
        "flag_128": "CU-128.png",
        "latitude": "21.54513189",
        "longitude": "-79.00064743",
        "zoom": "6"
    },
    {
        "id": "205",
        "enabled": "1",
        "code3l": "CUW",
        "code2l": "CW",
        "name": "Cura\u00e7ao",
        "name_official": "Cura\u00e7ao",
        "flag_32": "CW-32.png",
        "flag_128": "CW-128.png",
        "latitude": "12.20710309",
        "longitude": "-69.02160369",
        "zoom": "11"
    },
    {
        "id": "42",
        "enabled": "1",
        "code3l": "CYP",
        "code2l": "CY",
        "name": "Cyprus",
        "name_official": "Republic of Cyprus",
        "flag_32": "CY-32.png",
        "flag_128": "CY-128.png",
        "latitude": "35.12450768",
        "longitude": "33.42986100",
        "zoom": "9"
    },
    {
        "id": "43",
        "enabled": "1",
        "code3l": "CZE",
        "code2l": "CZ",
        "name": "Czechia",
        "name_official": "Czech Republic",
        "flag_32": "CZ-32.png",
        "flag_128": "CZ-128.png",
        "latitude": "49.76026136",
        "longitude": "15.53888197",
        "zoom": "7"
    },
    {
        "id": "183",
        "enabled": "1",
        "code3l": "ZAR",
        "code2l": "CD",
        "name": "Democratic Republic of the Congo",
        "name_official": "Democratic Republic of the Congo",
        "flag_32": "CD-32.png",
        "flag_128": "CD-128.png",
        "latitude": "-4.05373938",
        "longitude": "23.01110741",
        "zoom": "5"
    },
    {
        "id": "45",
        "enabled": "1",
        "code3l": "DNK",
        "code2l": "DK",
        "name": "Denmark",
        "name_official": "Kingdom of Denmark",
        "flag_32": "DK-32.png",
        "flag_128": "DK-128.png",
        "latitude": "54.71794021",
        "longitude": "9.41938953",
        "zoom": "6"
    },
    {
        "id": "46",
        "enabled": "1",
        "code3l": "DJI",
        "code2l": "DJ",
        "name": "Djibouti",
        "name_official": "Republic of Djibouti",
        "flag_32": "DJ-32.png",
        "flag_128": "DJ-128.png",
        "latitude": "11.75959257",
        "longitude": "42.65344839",
        "zoom": "8"
    },
    {
        "id": "47",
        "enabled": "1",
        "code3l": "DMA",
        "code2l": "DM",
        "name": "Dominica",
        "name_official": "Commonwealth of Dominica",
        "flag_32": "DM-32.png",
        "flag_128": "DM-128.png",
        "latitude": "15.41473963",
        "longitude": "-61.37097400",
        "zoom": "10"
    },
    {
        "id": "48",
        "enabled": "1",
        "code3l": "DOM",
        "code2l": "DO",
        "name": "Dominican Republic",
        "name_official": "Dominican Republic",
        "flag_32": "DO-32.png",
        "flag_128": "DO-128.png",
        "latitude": "18.73076761",
        "longitude": "-70.16264900",
        "zoom": "8"
    },
    {
        "id": "49",
        "enabled": "1",
        "code3l": "ECU",
        "code2l": "EC",
        "name": "Ecuador",
        "name_official": "Republic of Ecuador",
        "flag_32": "EC-32.png",
        "flag_128": "EC-128.png",
        "latitude": "-1.22919037",
        "longitude": "-78.55693916",
        "zoom": "6"
    },
    {
        "id": "50",
        "enabled": "1",
        "code3l": "EGY",
        "code2l": "EG",
        "name": "Egypt",
        "name_official": "Arab Republic of Egypt",
        "flag_32": "EG-32.png",
        "flag_128": "EG-128.png",
        "latitude": "26.71650873",
        "longitude": "30.80250000",
        "zoom": "6"
    },
    {
        "id": "51",
        "enabled": "1",
        "code3l": "SLV",
        "code2l": "SV",
        "name": "El Salvador",
        "name_official": "Republic of El Salvador",
        "flag_32": "SV-32.png",
        "flag_128": "SV-128.png",
        "latitude": "13.79043561",
        "longitude": "-88.89652800",
        "zoom": "8"
    },
    {
        "id": "52",
        "enabled": "1",
        "code3l": "GNQ",
        "code2l": "GQ",
        "name": "Equatorial Guinea",
        "name_official": "Republic of Equatorial Guinea",
        "flag_32": "GQ-32.png",
        "flag_128": "GQ-128.png",
        "latitude": "1.65068442",
        "longitude": "10.26789700",
        "zoom": "9"
    },
    {
        "id": "53",
        "enabled": "1",
        "code3l": "ERI",
        "code2l": "ER",
        "name": "Eritrea",
        "name_official": "State of Eritrea",
        "flag_32": "ER-32.png",
        "flag_128": "ER-128.png",
        "latitude": "15.21227764",
        "longitude": "39.61204792",
        "zoom": "7"
    },
    {
        "id": "54",
        "enabled": "1",
        "code3l": "EST",
        "code2l": "EE",
        "name": "Estonia",
        "name_official": "Republic of Estonia",
        "flag_32": "EE-32.png",
        "flag_128": "EE-128.png",
        "latitude": "58.74041141",
        "longitude": "25.38165099",
        "zoom": "7"
    },
    {
        "id": "55",
        "enabled": "1",
        "code3l": "ETH",
        "code2l": "ET",
        "name": "Ethiopia",
        "name_official": "Federal Democratic Republic of Ethiopia",
        "flag_32": "ET-32.png",
        "flag_128": "ET-128.png",
        "latitude": "9.10727589",
        "longitude": "39.84148164",
        "zoom": "6"
    },
    {
        "id": "184",
        "enabled": "1",
        "code3l": "EUR",
        "code2l": "EU",
        "name": "European Union",
        "name_official": "European Union",
        "flag_32": "EU-32.png",
        "flag_128": "EU-128.png",
        "latitude": "48.76380654",
        "longitude": "14.26843140",
        "zoom": "3"
    },
    {
        "id": "208",
        "enabled": "1",
        "code3l": "FLK",
        "code2l": "FK",
        "name": "Falkland Islands (Malvinas)",
        "name_official": "Falkland Islands",
        "flag_32": "FK-32.png",
        "flag_128": "FK-128.png",
        "latitude": "-51.78838251",
        "longitude": "-59.52361100",
        "zoom": "8"
    },
    {
        "id": "209",
        "enabled": "1",
        "code3l": "FRO",
        "code2l": "FO",
        "name": "Faroe Islands",
        "name_official": "Faroe Islands",
        "flag_32": "FO-32.png",
        "flag_128": "FO-128.png",
        "latitude": "61.88590482",
        "longitude": "-6.91180400",
        "zoom": "8"
    },
    {
        "id": "56",
        "enabled": "1",
        "code3l": "FJI",
        "code2l": "FJ",
        "name": "Fiji",
        "name_official": "Republic of Fiji",
        "flag_32": "FJ-32.png",
        "flag_128": "FJ-128.png",
        "latitude": "-17.71219757",
        "longitude": "178.06503600",
        "zoom": "9"
    },
    {
        "id": "57",
        "enabled": "1",
        "code3l": "FIN",
        "code2l": "FI",
        "name": "Finland",
        "name_official": "Republic of Finland",
        "flag_32": "FI-32.png",
        "flag_128": "FI-128.png",
        "latitude": "64.69610892",
        "longitude": "26.36339137",
        "zoom": "5"
    },
    {
        "id": "58",
        "enabled": "1",
        "code3l": "FRA",
        "code2l": "FR",
        "name": "France",
        "name_official": "French Republic",
        "flag_32": "FR-32.png",
        "flag_128": "FR-128.png",
        "latitude": "46.48372145",
        "longitude": "2.60926281",
        "zoom": "6"
    },
    {
        "id": "210",
        "enabled": "1",
        "code3l": "GUF",
        "code2l": "GF",
        "name": "French Guiana",
        "name_official": "French Guiana",
        "flag_32": "GF-32.png",
        "flag_128": "GF-128.png",
        "latitude": "4.01114381",
        "longitude": "-52.97746057",
        "zoom": "7"
    },
    {
        "id": "230",
        "enabled": "1",
        "code3l": "PYF",
        "code2l": "PF",
        "name": "French Polynesia",
        "name_official": "Territory of French Polynesia",
        "flag_32": "PF-32.png",
        "flag_128": "PF-128.png",
        "latitude": "-17.66243898",
        "longitude": "-149.40683900",
        "zoom": "10"
    },
    {
        "id": "240",
        "enabled": "1",
        "code3l": "ATF",
        "code2l": "TF",
        "name": "French Southern and Antarctic Lands",
        "name_official": "Territory of the French Southern and Antarctic Lands",
        "flag_32": "TF-32.png",
        "flag_128": "TF-128.png",
        "latitude": "-49.27235903",
        "longitude": "69.34856300",
        "zoom": "8"
    },
    {
        "id": "59",
        "enabled": "1",
        "code3l": "GAB",
        "code2l": "GA",
        "name": "Gabon",
        "name_official": "Gabonese Republic",
        "flag_32": "GA-32.png",
        "flag_128": "GA-128.png",
        "latitude": "-0.43426435",
        "longitude": "11.43916591",
        "zoom": "7"
    },
    {
        "id": "61",
        "enabled": "1",
        "code3l": "GEO",
        "code2l": "GE",
        "name": "Georgia",
        "name_official": "Georgia",
        "flag_32": "GE-32.png",
        "flag_128": "GE-128.png",
        "latitude": "41.82754301",
        "longitude": "44.17329916",
        "zoom": "7"
    },
    {
        "id": "62",
        "enabled": "1",
        "code3l": "DEU",
        "code2l": "DE",
        "name": "Germany",
        "name_official": "Federal Republic of Germany",
        "flag_32": "DE-32.png",
        "flag_128": "DE-128.png",
        "latitude": "50.82871201",
        "longitude": "10.97887975",
        "zoom": "6"
    },
    {
        "id": "63",
        "enabled": "1",
        "code3l": "GHA",
        "code2l": "GH",
        "name": "Ghana",
        "name_official": "Republic of Ghana",
        "flag_32": "GH-32.png",
        "flag_128": "GH-128.png",
        "latitude": "7.69154199",
        "longitude": "-1.29234904",
        "zoom": "7"
    },
    {
        "id": "212",
        "enabled": "1",
        "code3l": "GIB",
        "code2l": "GI",
        "name": "Gibraltar",
        "name_official": "Gibraltar",
        "flag_32": "GI-32.png",
        "flag_128": "GI-128.png",
        "latitude": "36.14864641",
        "longitude": "-5.34404779",
        "zoom": "12"
    },
    {
        "id": "64",
        "enabled": "1",
        "code3l": "GRC",
        "code2l": "GR",
        "name": "Greece",
        "name_official": "Hellenic Republic",
        "flag_32": "GR-32.png",
        "flag_128": "GR-128.png",
        "latitude": "38.52254746",
        "longitude": "24.53794505",
        "zoom": "6"
    },
    {
        "id": "213",
        "enabled": "1",
        "code3l": "GRL",
        "code2l": "GL",
        "name": "Greenland",
        "name_official": "Greenland",
        "flag_32": "GL-32.png",
        "flag_128": "GL-128.png",
        "latitude": "71.42932629",
        "longitude": "-34.38651956",
        "zoom": "3"
    },
    {
        "id": "65",
        "enabled": "1",
        "code3l": "GRD",
        "code2l": "GD",
        "name": "Grenada",
        "name_official": "Grenada",
        "flag_32": "GD-32.png",
        "flag_128": "GD-128.png",
        "latitude": "12.11644807",
        "longitude": "-61.67899400",
        "zoom": "11"
    },
    {
        "id": "214",
        "enabled": "1",
        "code3l": "GLP",
        "code2l": "GP",
        "name": "Guadeloupe",
        "name_official": "Department of Guadeloupe",
        "flag_32": "GP-32.png",
        "flag_128": "GP-128.png",
        "latitude": "16.26472785",
        "longitude": "-61.55099400",
        "zoom": "10"
    },
    {
        "id": "216",
        "enabled": "1",
        "code3l": "GUM",
        "code2l": "GU",
        "name": "Guam",
        "name_official": "Territory of Guam",
        "flag_32": "GU-32.png",
        "flag_128": "GU-128.png",
        "latitude": "13.44410137",
        "longitude": "144.80747791",
        "zoom": "10"
    },
    {
        "id": "66",
        "enabled": "1",
        "code3l": "GTM",
        "code2l": "GT",
        "name": "Guatemala",
        "name_official": "Republic of Guatemala",
        "flag_32": "GT-32.png",
        "flag_128": "GT-128.png",
        "latitude": "15.72598421",
        "longitude": "-89.96707712",
        "zoom": "7"
    },
    {
        "id": "211",
        "enabled": "1",
        "code3l": "GGY",
        "code2l": "GG",
        "name": "Guernsey",
        "name_official": "Bailiwick of Guernsey",
        "flag_32": "GG-32.png",
        "flag_128": "GG-128.png",
        "latitude": "49.46565975",
        "longitude": "-2.58527200",
        "zoom": "12"
    },
    {
        "id": "67",
        "enabled": "1",
        "code3l": "GIN",
        "code2l": "GN",
        "name": "Guinea",
        "name_official": "Republic of Guinea",
        "flag_32": "GN-32.png",
        "flag_128": "GN-128.png",
        "latitude": "9.94301472",
        "longitude": "-11.31711839",
        "zoom": "7"
    },
    {
        "id": "68",
        "enabled": "1",
        "code3l": "GNB",
        "code2l": "GW",
        "name": "Guinea-Bissau",
        "name_official": "Republic of Guinea-Bissau",
        "flag_32": "GW-32.png",
        "flag_128": "GW-128.png",
        "latitude": "11.80050682",
        "longitude": "-15.18040700",
        "zoom": "8"
    },
    {
        "id": "69",
        "enabled": "1",
        "code3l": "GUY",
        "code2l": "GY",
        "name": "Guyana",
        "name_official": "Republic of Guyana",
        "flag_32": "GY-32.png",
        "flag_128": "GY-128.png",
        "latitude": "4.47957059",
        "longitude": "-58.72692293",
        "zoom": "6"
    },
    {
        "id": "70",
        "enabled": "1",
        "code3l": "HTI",
        "code2l": "HT",
        "name": "Haiti",
        "name_official": "Republic of Haiti",
        "flag_32": "HT-32.png",
        "flag_128": "HT-128.png",
        "latitude": "19.07430861",
        "longitude": "-72.79607526",
        "zoom": "8"
    },
    {
        "id": "218",
        "enabled": "1",
        "code3l": "HMD",
        "code2l": "HM",
        "name": "Heard Island And McDonald Islands",
        "name_official": "Heard and McDonald Islands",
        "flag_32": "HM-32.png",
        "flag_128": "HM-128.png",
        "latitude": "-53.08168847",
        "longitude": "73.50415800",
        "zoom": "11"
    },
    {
        "id": "71",
        "enabled": "1",
        "code3l": "HND",
        "code2l": "HN",
        "name": "Honduras",
        "name_official": "Republic of Honduras",
        "flag_32": "HN-32.png",
        "flag_128": "HN-128.png",
        "latitude": "14.64994423",
        "longitude": "-87.01643713",
        "zoom": "7"
    },
    {
        "id": "217",
        "enabled": "1",
        "code3l": "HKG",
        "code2l": "HK",
        "name": "Hong Kong",
        "name_official": "Hong Kong Special Administrative Region of the People's Republic",
        "flag_32": "HK-32.png",
        "flag_128": "HK-128.png",
        "latitude": "22.33728531",
        "longitude": "114.14657786",
        "zoom": "11"
    },
    {
        "id": "72",
        "enabled": "1",
        "code3l": "HUN",
        "code2l": "HU",
        "name": "Hungary",
        "name_official": "Republic of Hungary",
        "flag_32": "HU-32.png",
        "flag_128": "HU-128.png",
        "latitude": "46.97670384",
        "longitude": "19.35499657",
        "zoom": "7"
    },
    {
        "id": "73",
        "enabled": "1",
        "code3l": "ISL",
        "code2l": "IS",
        "name": "Iceland",
        "name_official": "Republic of Iceland",
        "flag_32": "IS-32.png",
        "flag_128": "IS-128.png",
        "latitude": "64.99294495",
        "longitude": "-18.57038755",
        "zoom": "6"
    },
    {
        "id": "74",
        "enabled": "1",
        "code3l": "IND",
        "code2l": "IN",
        "name": "India",
        "name_official": "Republic of India",
        "flag_32": "IN-32.png",
        "flag_128": "IN-128.png",
        "latitude": "20.46549519",
        "longitude": "78.50146222",
        "zoom": "4"
    },
    {
        "id": "75",
        "enabled": "1",
        "code3l": "IDN",
        "code2l": "ID",
        "name": "Indonesia",
        "name_official": "Republic of Indonesia",
        "flag_32": "ID-32.png",
        "flag_128": "ID-128.png",
        "latitude": "-2.46229680",
        "longitude": "121.18329789",
        "zoom": "4"
    },
    {
        "id": "187",
        "enabled": "1",
        "code3l": "IRN",
        "code2l": "IR",
        "name": "Iran",
        "name_official": "Islamic Republic of Iran",
        "flag_32": "IR-32.png",
        "flag_128": "IR-128.png",
        "latitude": "31.40240324",
        "longitude": "51.28204814",
        "zoom": "5"
    },
    {
        "id": "76",
        "enabled": "1",
        "code3l": "IRQ",
        "code2l": "IQ",
        "name": "Iraq",
        "name_official": "Republic of Iraq",
        "flag_32": "IQ-32.png",
        "flag_128": "IQ-128.png",
        "latitude": "32.90170182",
        "longitude": "43.19590056",
        "zoom": "6"
    },
    {
        "id": "77",
        "enabled": "1",
        "code3l": "IRL",
        "code2l": "IE",
        "name": "Ireland",
        "name_official": "Ireland",
        "flag_32": "IE-32.png",
        "flag_128": "IE-128.png",
        "latitude": "53.10101628",
        "longitude": "-8.21092302",
        "zoom": "6"
    },
    {
        "id": "219",
        "enabled": "1",
        "code3l": "IMN",
        "code2l": "IM",
        "name": "Isle of Man",
        "name_official": "The Isle of Man",
        "flag_32": "IM-32.png",
        "flag_128": "IM-128.png",
        "latitude": "54.23562697",
        "longitude": "-4.54805400",
        "zoom": "10"
    },
    {
        "id": "78",
        "enabled": "1",
        "code3l": "ISR",
        "code2l": "IL",
        "name": "Israel",
        "name_official": "State of Israel",
        "flag_32": "IL-32.png",
        "flag_128": "IL-128.png",
        "latitude": "30.85883075",
        "longitude": "34.91753797",
        "zoom": "7"
    },
    {
        "id": "79",
        "enabled": "1",
        "code3l": "ITA",
        "code2l": "IT",
        "name": "Italy",
        "name_official": "Republic of Italy",
        "flag_32": "IT-32.png",
        "flag_128": "IT-128.png",
        "latitude": "41.77810840",
        "longitude": "12.67725128",
        "zoom": "5"
    },
    {
        "id": "80",
        "enabled": "1",
        "code3l": "JAM",
        "code2l": "JM",
        "name": "Jamaica",
        "name_official": "Jamaica",
        "flag_32": "JM-32.png",
        "flag_128": "JM-128.png",
        "latitude": "18.10838487",
        "longitude": "-77.29750600",
        "zoom": "9"
    },
    {
        "id": "81",
        "enabled": "1",
        "code3l": "JPN",
        "code2l": "JP",
        "name": "Japan",
        "name_official": "Japan",
        "flag_32": "JP-32.png",
        "flag_128": "JP-128.png",
        "latitude": "37.51848822",
        "longitude": "137.67066061",
        "zoom": "5"
    },
    {
        "id": "221",
        "enabled": "1",
        "code3l": "JEY",
        "code2l": "JE",
        "name": "Jersey",
        "name_official": "Bailiwick of Jersey",
        "flag_32": "JE-32.png",
        "flag_128": "JE-128.png",
        "latitude": "49.21440771",
        "longitude": "-2.13124600",
        "zoom": "12"
    },
    {
        "id": "82",
        "enabled": "1",
        "code3l": "JOR",
        "code2l": "JO",
        "name": "Jordan",
        "name_official": "Hashemite Kingdom of Jordan",
        "flag_32": "JO-32.png",
        "flag_128": "JO-128.png",
        "latitude": "31.31616588",
        "longitude": "36.37575510",
        "zoom": "7"
    },
    {
        "id": "83",
        "enabled": "1",
        "code3l": "KAZ",
        "code2l": "KZ",
        "name": "Kazakhstan",
        "name_official": "Republic of Kazakhstan",
        "flag_32": "KZ-32.png",
        "flag_128": "KZ-128.png",
        "latitude": "45.38592596",
        "longitude": "68.81334444",
        "zoom": "4"
    },
    {
        "id": "84",
        "enabled": "1",
        "code3l": "KEN",
        "code2l": "KE",
        "name": "Kenya",
        "name_official": "Republic of Kenya",
        "flag_32": "KE-32.png",
        "flag_128": "KE-128.png",
        "latitude": "0.19582452",
        "longitude": "37.97212297",
        "zoom": "6"
    },
    {
        "id": "85",
        "enabled": "1",
        "code3l": "KIR",
        "code2l": "KI",
        "name": "Kiribati",
        "name_official": "Republic of Kiribati",
        "flag_32": "KI-32.png",
        "flag_128": "KI-128.png",
        "latitude": "1.87085244",
        "longitude": "-157.36259310",
        "zoom": "10"
    },
    {
        "id": "86",
        "enabled": "1",
        "code3l": "KWT",
        "code2l": "KW",
        "name": "Kuwait",
        "name_official": "State of Kuwait",
        "flag_32": "KW-32.png",
        "flag_128": "KW-128.png",
        "latitude": "29.43253341",
        "longitude": "47.71798405",
        "zoom": "8"
    },
    {
        "id": "87",
        "enabled": "1",
        "code3l": "KGZ",
        "code2l": "KG",
        "name": "Kyrgyzstan",
        "name_official": "Kyrgyz Republic",
        "flag_32": "KG-32.png",
        "flag_128": "KG-128.png",
        "latitude": "41.11509878",
        "longitude": "74.25524574",
        "zoom": "6"
    },
    {
        "id": "88",
        "enabled": "1",
        "code3l": "LAO",
        "code2l": "LA",
        "name": "Laos",
        "name_official": "Lao People\u2019s Democratic Republic",
        "flag_32": "LA-32.png",
        "flag_128": "LA-128.png",
        "latitude": "17.76075593",
        "longitude": "103.61611347",
        "zoom": "6"
    },
    {
        "id": "89",
        "enabled": "1",
        "code3l": "LVA",
        "code2l": "LV",
        "name": "Latvia",
        "name_official": "Republic of Latvia",
        "flag_32": "LV-32.png",
        "flag_128": "LV-128.png",
        "latitude": "56.86697515",
        "longitude": "24.54826936",
        "zoom": "7"
    },
    {
        "id": "90",
        "enabled": "1",
        "code3l": "LBN",
        "code2l": "LB",
        "name": "Lebanon",
        "name_official": "Lebanese Republic",
        "flag_32": "LB-32.png",
        "flag_128": "LB-128.png",
        "latitude": "34.08249284",
        "longitude": "35.66454309",
        "zoom": "8"
    },
    {
        "id": "91",
        "enabled": "1",
        "code3l": "LSO",
        "code2l": "LS",
        "name": "Lesotho",
        "name_official": "Kingdom of Lesotho",
        "flag_32": "LS-32.png",
        "flag_128": "LS-128.png",
        "latitude": "-29.60303205",
        "longitude": "28.23361200",
        "zoom": "8"
    },
    {
        "id": "92",
        "enabled": "1",
        "code3l": "LBR",
        "code2l": "LR",
        "name": "Liberia",
        "name_official": "Republic of Liberia",
        "flag_32": "LR-32.png",
        "flag_128": "LR-128.png",
        "latitude": "6.44154681",
        "longitude": "-9.39103485",
        "zoom": "7"
    },
    {
        "id": "93",
        "enabled": "1",
        "code3l": "LBY",
        "code2l": "LY",
        "name": "Libya",
        "name_official": "Socialist People\u2019s Libyan Arab Jamahiriya",
        "flag_32": "LY-32.png",
        "flag_128": "LY-128.png",
        "latitude": "27.06902914",
        "longitude": "18.19513987",
        "zoom": "5"
    },
    {
        "id": "94",
        "enabled": "1",
        "code3l": "LIE",
        "code2l": "LI",
        "name": "Liechtenstein",
        "name_official": "Principality of Liechtenstein",
        "flag_32": "LI-32.png",
        "flag_128": "LI-128.png",
        "latitude": "47.16587383",
        "longitude": "9.55537700",
        "zoom": "11"
    },
    {
        "id": "95",
        "enabled": "1",
        "code3l": "LTU",
        "code2l": "LT",
        "name": "Lithuania",
        "name_official": "Republic of Lithuania",
        "flag_32": "LT-32.png",
        "flag_128": "LT-128.png",
        "latitude": "55.25095948",
        "longitude": "23.80987587",
        "zoom": "7"
    },
    {
        "id": "96",
        "enabled": "1",
        "code3l": "LUX",
        "code2l": "LU",
        "name": "Luxembourg",
        "name_official": "Grand Duchy of Luxembourg",
        "flag_32": "LU-32.png",
        "flag_128": "LU-128.png",
        "latitude": "49.81327712",
        "longitude": "6.12958700",
        "zoom": "9"
    },
    {
        "id": "224",
        "enabled": "1",
        "code3l": "MAC",
        "code2l": "MO",
        "name": "Macao",
        "name_official": "Macau Special Administrative Region",
        "flag_32": "MO-32.png",
        "flag_128": "MO-128.png",
        "latitude": "22.19872287",
        "longitude": "113.54387700",
        "zoom": "12"
    },
    {
        "id": "191",
        "enabled": "1",
        "code3l": "MKD",
        "code2l": "MK",
        "name": "Macedonia",
        "name_official": "The former Yugoslav Republic of Macedonia",
        "flag_32": "MK-32.png",
        "flag_128": "MK-128.png",
        "latitude": "41.60059479",
        "longitude": "21.74527900",
        "zoom": "8"
    },
    {
        "id": "97",
        "enabled": "1",
        "code3l": "MDG",
        "code2l": "MG",
        "name": "Madagascar",
        "name_official": "Republic of Madagascar",
        "flag_32": "MG-32.png",
        "flag_128": "MG-128.png",
        "latitude": "-19.79858543",
        "longitude": "46.97898228",
        "zoom": "5"
    },
    {
        "id": "98",
        "enabled": "1",
        "code3l": "MWI",
        "code2l": "MW",
        "name": "Malawi",
        "name_official": "Republic of Malawi",
        "flag_32": "MW-32.png",
        "flag_128": "MW-128.png",
        "latitude": "-12.48684092",
        "longitude": "34.14223524",
        "zoom": "6"
    },
    {
        "id": "99",
        "enabled": "1",
        "code3l": "MYS",
        "code2l": "MY",
        "name": "Malaysia",
        "name_official": "Malaysia",
        "flag_32": "MY-32.png",
        "flag_128": "MY-128.png",
        "latitude": "4.97345793",
        "longitude": "106.54609050",
        "zoom": "5"
    },
    {
        "id": "100",
        "enabled": "1",
        "code3l": "MDV",
        "code2l": "MV",
        "name": "Maldives",
        "name_official": "Republic of Maldives",
        "flag_32": "MV-32.png",
        "flag_128": "MV-128.png",
        "latitude": "-0.64224221",
        "longitude": "73.13373313",
        "zoom": "12"
    },
    {
        "id": "101",
        "enabled": "1",
        "code3l": "MLI",
        "code2l": "ML",
        "name": "Mali",
        "name_official": "Republic of Mali",
        "flag_32": "ML-32.png",
        "flag_128": "ML-128.png",
        "latitude": "17.69385811",
        "longitude": "-1.96368730",
        "zoom": "5"
    },
    {
        "id": "102",
        "enabled": "1",
        "code3l": "MLT",
        "code2l": "MT",
        "name": "Malta",
        "name_official": "Republic of Malta",
        "flag_32": "MT-32.png",
        "flag_128": "MT-128.png",
        "latitude": "35.89706403",
        "longitude": "14.43687877",
        "zoom": "11"
    },
    {
        "id": "103",
        "enabled": "1",
        "code3l": "MHL",
        "code2l": "MH",
        "name": "Marshall Islands",
        "name_official": "Republic of the Marshall Islands",
        "flag_32": "MH-32.png",
        "flag_128": "MH-128.png",
        "latitude": "7.30130732",
        "longitude": "168.75512619",
        "zoom": "10"
    },
    {
        "id": "226",
        "enabled": "1",
        "code3l": "MTQ",
        "code2l": "MQ",
        "name": "Martinique",
        "name_official": "Department of Martinique",
        "flag_32": "MQ-32.png",
        "flag_128": "MQ-128.png",
        "latitude": "14.64128045",
        "longitude": "-61.02417600",
        "zoom": "10"
    },
    {
        "id": "104",
        "enabled": "1",
        "code3l": "MRT",
        "code2l": "MR",
        "name": "Mauritania",
        "name_official": "Islamic Republic of Mauritania",
        "flag_32": "MR-32.png",
        "flag_128": "MR-128.png",
        "latitude": "20.28331239",
        "longitude": "-10.21573334",
        "zoom": "5"
    },
    {
        "id": "105",
        "enabled": "1",
        "code3l": "MUS",
        "code2l": "MU",
        "name": "Mauritius",
        "name_official": "Republic of Mauritius",
        "flag_32": "MU-32.png",
        "flag_128": "MU-128.png",
        "latitude": "-20.28368188",
        "longitude": "57.56588291",
        "zoom": "10"
    },
    {
        "id": "249",
        "enabled": "1",
        "code3l": "MYT",
        "code2l": "YT",
        "name": "Mayotte",
        "name_official": "Overseas Department of Mayotte",
        "flag_32": "YT-32.png",
        "flag_128": "YT-128.png",
        "latitude": "-12.82744522",
        "longitude": "45.16624200",
        "zoom": "11"
    },
    {
        "id": "106",
        "enabled": "1",
        "code3l": "MEX",
        "code2l": "MX",
        "name": "Mexico",
        "name_official": "United Mexican States",
        "flag_32": "MX-32.png",
        "flag_128": "MX-128.png",
        "latitude": "22.92036676",
        "longitude": "-102.33305344",
        "zoom": "5"
    },
    {
        "id": "185",
        "enabled": "1",
        "code3l": "FSM",
        "code2l": "FM",
        "name": "Micronesia",
        "name_official": "Federated States of Micronesia",
        "flag_32": "FM-32.png",
        "flag_128": "FM-128.png",
        "latitude": "6.88747377",
        "longitude": "158.21507170",
        "zoom": "12"
    },
    {
        "id": "190",
        "enabled": "1",
        "code3l": "MDA",
        "code2l": "MD",
        "name": "Moldova",
        "name_official": "Republic of Moldova",
        "flag_32": "MD-32.png",
        "flag_128": "MD-128.png",
        "latitude": "47.10710437",
        "longitude": "28.54018109",
        "zoom": "7"
    },
    {
        "id": "107",
        "enabled": "1",
        "code3l": "MCO",
        "code2l": "MC",
        "name": "Monaco",
        "name_official": "Principality of Monaco",
        "flag_32": "MC-32.png",
        "flag_128": "MC-128.png",
        "latitude": "43.70463620",
        "longitude": "6.75444978",
        "zoom": "9"
    },
    {
        "id": "108",
        "enabled": "1",
        "code3l": "MNG",
        "code2l": "MN",
        "name": "Mongolia",
        "name_official": "Mongolia",
        "flag_32": "MN-32.png",
        "flag_128": "MN-128.png",
        "latitude": "46.80556270",
        "longitude": "104.30808978",
        "zoom": "5"
    },
    {
        "id": "109",
        "enabled": "1",
        "code3l": "MNE",
        "code2l": "ME",
        "name": "Montenegro",
        "name_official": "Montenegro",
        "flag_32": "ME-32.png",
        "flag_128": "ME-128.png",
        "latitude": "42.71699590",
        "longitude": "19.09699321",
        "zoom": "8"
    },
    {
        "id": "227",
        "enabled": "1",
        "code3l": "MSR",
        "code2l": "MS",
        "name": "Montserrat",
        "name_official": "Montserrat",
        "flag_32": "MS-32.png",
        "flag_128": "MS-128.png",
        "latitude": "16.74774077",
        "longitude": "-62.18736600",
        "zoom": "12"
    },
    {
        "id": "110",
        "enabled": "1",
        "code3l": "MAR",
        "code2l": "MA",
        "name": "Morocco",
        "name_official": "Kingdom of Morocco",
        "flag_32": "MA-32.png",
        "flag_128": "MA-128.png",
        "latitude": "31.95441758",
        "longitude": "-7.26839325",
        "zoom": "6"
    },
    {
        "id": "111",
        "enabled": "1",
        "code3l": "MOZ",
        "code2l": "MZ",
        "name": "Mozambique",
        "name_official": "Republic of Mozambique",
        "flag_32": "MZ-32.png",
        "flag_128": "MZ-128.png",
        "latitude": "-19.07617816",
        "longitude": "33.81570282",
        "zoom": "5"
    },
    {
        "id": "112",
        "enabled": "1",
        "code3l": "MMR",
        "code2l": "MM",
        "name": "Myanmar",
        "name_official": "Union of Myanmar",
        "flag_32": "MM-32.png",
        "flag_128": "MM-128.png",
        "latitude": "19.20985380",
        "longitude": "96.54949272",
        "zoom": "5"
    },
    {
        "id": "113",
        "enabled": "1",
        "code3l": "NAM",
        "code2l": "NA",
        "name": "Namibia",
        "name_official": "Republic of Namibia",
        "flag_32": "NA-32.png",
        "flag_128": "NA-128.png",
        "latitude": "-22.70965620",
        "longitude": "16.72161918",
        "zoom": "6"
    },
    {
        "id": "114",
        "enabled": "1",
        "code3l": "NRU",
        "code2l": "NR",
        "name": "Nauru",
        "name_official": "Republic of Nauru",
        "flag_32": "NR-32.png",
        "flag_128": "NR-128.png",
        "latitude": "-0.52586763",
        "longitude": "166.93270463",
        "zoom": "13"
    },
    {
        "id": "115",
        "enabled": "1",
        "code3l": "NPL",
        "code2l": "NP",
        "name": "Nepal",
        "name_official": "Federal Democratic Republic of Nepal",
        "flag_32": "NP-32.png",
        "flag_128": "NP-128.png",
        "latitude": "28.28430770",
        "longitude": "83.98119373",
        "zoom": "7"
    },
    {
        "id": "116",
        "enabled": "1",
        "code3l": "NLD",
        "code2l": "NL",
        "name": "Netherlands",
        "name_official": "Kingdom of the Netherlands",
        "flag_32": "NL-32.png",
        "flag_128": "NL-128.png",
        "latitude": "52.33939951",
        "longitude": "4.98914998",
        "zoom": "7"
    },
    {
        "id": "228",
        "enabled": "1",
        "code3l": "NCL",
        "code2l": "NC",
        "name": "New Caledonia",
        "name_official": "Territory of New Caledonia and Dependencies",
        "flag_32": "NC-32.png",
        "flag_128": "NC-128.png",
        "latitude": "-21.26104020",
        "longitude": "165.58783760",
        "zoom": "8"
    },
    {
        "id": "117",
        "enabled": "1",
        "code3l": "NZL",
        "code2l": "NZ",
        "name": "New Zealand",
        "name_official": "New Zealand",
        "flag_32": "NZ-32.png",
        "flag_128": "NZ-128.png",
        "latitude": "-40.95025298",
        "longitude": "171.76586181",
        "zoom": "5"
    },
    {
        "id": "118",
        "enabled": "1",
        "code3l": "NIC",
        "code2l": "NI",
        "name": "Nicaragua",
        "name_official": "Republic of Nicaragua",
        "flag_32": "NI-32.png",
        "flag_128": "NI-128.png",
        "latitude": "12.91806226",
        "longitude": "-84.82270352",
        "zoom": "7"
    },
    {
        "id": "119",
        "enabled": "1",
        "code3l": "NER",
        "code2l": "NE",
        "name": "Niger",
        "name_official": "Republic of the Niger",
        "flag_32": "NE-32.png",
        "flag_128": "NE-128.png",
        "latitude": "17.23446679",
        "longitude": "8.23547860",
        "zoom": "6"
    },
    {
        "id": "120",
        "enabled": "1",
        "code3l": "NGA",
        "code2l": "NG",
        "name": "Nigeria",
        "name_official": "Federal Republic of Nigeria",
        "flag_32": "NG-32.png",
        "flag_128": "NG-128.png",
        "latitude": "9.02165273",
        "longitude": "7.82933373",
        "zoom": "6"
    },
    {
        "id": "192",
        "enabled": "1",
        "code3l": "NIU",
        "code2l": "NU",
        "name": "Niue",
        "name_official": "Niue",
        "flag_32": "NU-32.png",
        "flag_128": "NU-128.png",
        "latitude": "-19.04976362",
        "longitude": "-169.86585571",
        "zoom": "11"
    },
    {
        "id": "229",
        "enabled": "1",
        "code3l": "NFK",
        "code2l": "NF",
        "name": "Norfolk Island",
        "name_official": "Norfolk Islands",
        "flag_32": "NF-32.png",
        "flag_128": "NF-128.png",
        "latitude": "-29.02801043",
        "longitude": "167.94303023",
        "zoom": "13"
    },
    {
        "id": "188",
        "enabled": "1",
        "code3l": "PRK",
        "code2l": "KP",
        "name": "North Korea",
        "name_official": "Democratic People's Republic of Korea",
        "flag_32": "KP-32.png",
        "flag_128": "KP-128.png",
        "latitude": "40.00785500",
        "longitude": "127.48812834",
        "zoom": "6"
    },
    {
        "id": "225",
        "enabled": "1",
        "code3l": "MNP",
        "code2l": "MP",
        "name": "Northern Mariana Islands",
        "name_official": "Commonwealth of the Northern Mariana Islands",
        "flag_32": "MP-32.png",
        "flag_128": "MP-128.png",
        "latitude": "15.09783636",
        "longitude": "145.67390000",
        "zoom": "11"
    },
    {
        "id": "121",
        "enabled": "1",
        "code3l": "NOR",
        "code2l": "NO",
        "name": "Norway",
        "name_official": "Kingdom of Norway",
        "flag_32": "NO-32.png",
        "flag_128": "NO-128.png",
        "latitude": "65.04680297",
        "longitude": "13.50069228",
        "zoom": "4"
    },
    {
        "id": "122",
        "enabled": "1",
        "code3l": "OMN",
        "code2l": "OM",
        "name": "Oman",
        "name_official": "Sultanate of Oman",
        "flag_32": "OM-32.png",
        "flag_128": "OM-128.png",
        "latitude": "20.69906846",
        "longitude": "56.69230596",
        "zoom": "6"
    },
    {
        "id": "123",
        "enabled": "1",
        "code3l": "PAK",
        "code2l": "PK",
        "name": "Pakistan",
        "name_official": "Islamic Republic of Pakistan",
        "flag_32": "PK-32.png",
        "flag_128": "PK-128.png",
        "latitude": "29.90335974",
        "longitude": "70.34487986",
        "zoom": "5"
    },
    {
        "id": "124",
        "enabled": "1",
        "code3l": "PLW",
        "code2l": "PW",
        "name": "Palau",
        "name_official": "Republic of Palau",
        "flag_32": "PW-32.png",
        "flag_128": "PW-128.png",
        "latitude": "7.49856307",
        "longitude": "134.57291496",
        "zoom": "10"
    },
    {
        "id": "234",
        "enabled": "1",
        "code3l": "PSE",
        "code2l": "PS",
        "name": "Palestinian Territory, Occupied",
        "name_official": "the Occupied Palestinian Territory",
        "flag_32": "PS-32.png",
        "flag_128": "PS-128.png",
        "latitude": "32.26367103",
        "longitude": "35.21936714",
        "zoom": "8"
    },
    {
        "id": "125",
        "enabled": "1",
        "code3l": "PAN",
        "code2l": "PA",
        "name": "Panama",
        "name_official": "Republic of Panama",
        "flag_32": "PA-32.png",
        "flag_128": "PA-128.png",
        "latitude": "8.52135102",
        "longitude": "-80.04603702",
        "zoom": "7"
    },
    {
        "id": "126",
        "enabled": "1",
        "code3l": "PNG",
        "code2l": "PG",
        "name": "Papua New Guinea",
        "name_official": "Papua New Guinea",
        "flag_32": "PG-32.png",
        "flag_128": "PG-128.png",
        "latitude": "-6.62414046",
        "longitude": "144.44993477",
        "zoom": "7"
    },
    {
        "id": "127",
        "enabled": "1",
        "code3l": "PRY",
        "code2l": "PY",
        "name": "Paraguay",
        "name_official": "Republic of Paraguay",
        "flag_32": "PY-32.png",
        "flag_128": "PY-128.png",
        "latitude": "-23.38564782",
        "longitude": "-58.29551057",
        "zoom": "6"
    },
    {
        "id": "128",
        "enabled": "1",
        "code3l": "PER",
        "code2l": "PE",
        "name": "Peru",
        "name_official": "Republic of Peru",
        "flag_32": "PE-32.png",
        "flag_128": "PE-128.png",
        "latitude": "-8.50205247",
        "longitude": "-76.15772412",
        "zoom": "5"
    },
    {
        "id": "129",
        "enabled": "1",
        "code3l": "PHL",
        "code2l": "PH",
        "name": "Philippines",
        "name_official": "Republic of the Philippines",
        "flag_32": "PH-32.png",
        "flag_128": "PH-128.png",
        "latitude": "12.82361200",
        "longitude": "121.77401700",
        "zoom": "6"
    },
    {
        "id": "232",
        "enabled": "1",
        "code3l": "PCN",
        "code2l": "PN",
        "name": "Pitcairn Islands",
        "name_official": "Pitcairn Group of Islands",
        "flag_32": "PN-32.png",
        "flag_128": "PN-128.png",
        "latitude": "-24.37673925",
        "longitude": "-128.32423730",
        "zoom": "13"
    },
    {
        "id": "130",
        "enabled": "1",
        "code3l": "POL",
        "code2l": "PL",
        "name": "Poland",
        "name_official": "Republic of Poland",
        "flag_32": "PL-32.png",
        "flag_128": "PL-128.png",
        "latitude": "52.10117636",
        "longitude": "19.33190957",
        "zoom": "6"
    },
    {
        "id": "131",
        "enabled": "1",
        "code3l": "PRT",
        "code2l": "PT",
        "name": "Portugal",
        "name_official": "Portuguese Republic",
        "flag_32": "PT-32.png",
        "flag_128": "PT-128.png",
        "latitude": "39.44879136",
        "longitude": "-8.03768042",
        "zoom": "6"
    },
    {
        "id": "233",
        "enabled": "1",
        "code3l": "PRI",
        "code2l": "PR",
        "name": "Puerto Rico",
        "name_official": "Commonwealth of Puerto Rico",
        "flag_32": "PR-32.png",
        "flag_128": "PR-128.png",
        "latitude": "18.21963053",
        "longitude": "-66.59015100",
        "zoom": "9"
    },
    {
        "id": "132",
        "enabled": "1",
        "code3l": "QAT",
        "code2l": "QA",
        "name": "Qatar",
        "name_official": "State of Qatar",
        "flag_32": "QA-32.png",
        "flag_128": "QA-128.png",
        "latitude": "25.24551555",
        "longitude": "51.24431480",
        "zoom": "8"
    },
    {
        "id": "235",
        "enabled": "1",
        "code3l": "REU",
        "code2l": "RE",
        "name": "R\u00e9union",
        "name_official": "Department of Reunion",
        "flag_32": "RE-32.png",
        "flag_128": "RE-128.png",
        "latitude": "-21.11480084",
        "longitude": "55.53638200",
        "zoom": "10"
    },
    {
        "id": "133",
        "enabled": "1",
        "code3l": "ROU",
        "code2l": "RO",
        "name": "Romania",
        "name_official": "Romania",
        "flag_32": "RO-32.png",
        "flag_128": "RO-128.png",
        "latitude": "45.56450023",
        "longitude": "25.21945155",
        "zoom": "6"
    },
    {
        "id": "134",
        "enabled": "1",
        "code3l": "RUS",
        "code2l": "RU",
        "name": "Russia",
        "name_official": "Russian Federation",
        "flag_32": "RU-32.png",
        "flag_128": "RU-128.png",
        "latitude": "57.96812298",
        "longitude": "102.41837137",
        "zoom": "3"
    },
    {
        "id": "135",
        "enabled": "1",
        "code3l": "RWA",
        "code2l": "RW",
        "name": "Rwanda",
        "name_official": "Republic of Rwanda",
        "flag_32": "RW-32.png",
        "flag_128": "RW-128.png",
        "latitude": "-1.98589079",
        "longitude": "29.94255855",
        "zoom": "8"
    },
    {
        "id": "200",
        "enabled": "1",
        "code3l": "BLM",
        "code2l": "BL",
        "name": "Saint Barth\u00e9lemy",
        "name_official": "Territorial collectivity of Saint Barth\u00e9lemy",
        "flag_32": "BL-32.png",
        "flag_128": "BL-128.png",
        "latitude": "17.90042417",
        "longitude": "-62.83376215",
        "zoom": "13"
    },
    {
        "id": "236",
        "enabled": "1",
        "code3l": "SHN",
        "code2l": "SH",
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "name_official": "Saint Helena, Ascension and Tristan da Cunha",
        "flag_32": "SH-32.png",
        "flag_128": "SH-128.png",
        "latitude": "-37.10521846",
        "longitude": "-12.27768580",
        "zoom": "12"
    },
    {
        "id": "136",
        "enabled": "1",
        "code3l": "KNA",
        "code2l": "KN",
        "name": "Saint Kitts and Nevis",
        "name_official": "Saint Kitts and Nevis",
        "flag_32": "KN-32.png",
        "flag_128": "KN-128.png",
        "latitude": "17.33453669",
        "longitude": "-62.76411725",
        "zoom": "12"
    },
    {
        "id": "137",
        "enabled": "1",
        "code3l": "LCA",
        "code2l": "LC",
        "name": "Saint Lucia",
        "name_official": "Saint Lucia",
        "flag_32": "LC-32.png",
        "flag_128": "LC-128.png",
        "latitude": "13.90938495",
        "longitude": "-60.97889500",
        "zoom": "11"
    },
    {
        "id": "223",
        "enabled": "1",
        "code3l": "MAF",
        "code2l": "MF",
        "name": "Saint Martin",
        "name_official": "Saint Martin",
        "flag_32": "MF-32.png",
        "flag_128": "MF-128.png",
        "latitude": "18.07637107",
        "longitude": "-63.05019106",
        "zoom": "12"
    },
    {
        "id": "231",
        "enabled": "1",
        "code3l": "SPM",
        "code2l": "PM",
        "name": "Saint Pierre and Miquelon",
        "name_official": "Saint Pierre and Miquelon",
        "flag_32": "PM-32.png",
        "flag_128": "PM-128.png",
        "latitude": "46.88469499",
        "longitude": "-56.31590200",
        "zoom": "10"
    },
    {
        "id": "138",
        "enabled": "1",
        "code3l": "VCT",
        "code2l": "VC",
        "name": "Saint Vincent and the Grenadines",
        "name_official": "Saint Vincent and the Grenadines",
        "flag_32": "VC-32.png",
        "flag_128": "VC-128.png",
        "latitude": "13.25276143",
        "longitude": "-61.19709800",
        "zoom": "11"
    },
    {
        "id": "139",
        "enabled": "1",
        "code3l": "WSM",
        "code2l": "WS",
        "name": "Samoa",
        "name_official": "Independent State of Samoa",
        "flag_32": "WS-32.png",
        "flag_128": "WS-128.png",
        "latitude": "-13.57998954",
        "longitude": "-172.45207363",
        "zoom": "10"
    },
    {
        "id": "140",
        "enabled": "1",
        "code3l": "SMR",
        "code2l": "SM",
        "name": "San Marino",
        "name_official": "Republic of San Marino",
        "flag_32": "SM-32.png",
        "flag_128": "SM-128.png",
        "latitude": "43.94223356",
        "longitude": "12.45777700",
        "zoom": "11"
    },
    {
        "id": "141",
        "enabled": "1",
        "code3l": "STP",
        "code2l": "ST",
        "name": "Sao Tome and Principe",
        "name_official": "Democratic Republic of Sao Tome and  Principe",
        "flag_32": "ST-32.png",
        "flag_128": "ST-128.png",
        "latitude": "0.23381910",
        "longitude": "6.59935809",
        "zoom": "10"
    },
    {
        "id": "142",
        "enabled": "1",
        "code3l": "SAU",
        "code2l": "SA",
        "name": "Saudi Arabia",
        "name_official": "Kingdom of Saudi Arabia",
        "flag_32": "SA-32.png",
        "flag_128": "SA-128.png",
        "latitude": "24.16687314",
        "longitude": "42.88190638",
        "zoom": "5"
    },
    {
        "id": "143",
        "enabled": "1",
        "code3l": "SEN",
        "code2l": "SN",
        "name": "Senegal",
        "name_official": "Republic of Senegal",
        "flag_32": "SN-32.png",
        "flag_128": "SN-128.png",
        "latitude": "14.43579003",
        "longitude": "-14.68306489",
        "zoom": "7"
    },
    {
        "id": "144",
        "enabled": "1",
        "code3l": "SRB",
        "code2l": "RS",
        "name": "Serbia",
        "name_official": "Republic of Serbia",
        "flag_32": "RS-32.png",
        "flag_128": "RS-128.png",
        "latitude": "44.06736041",
        "longitude": "20.29725084",
        "zoom": "7"
    },
    {
        "id": "145",
        "enabled": "1",
        "code3l": "SYC",
        "code2l": "SC",
        "name": "Seychelles",
        "name_official": "Republic of Seychelles",
        "flag_32": "SC-32.png",
        "flag_128": "SC-128.png",
        "latitude": "-4.68053204",
        "longitude": "55.49061371",
        "zoom": "11"
    },
    {
        "id": "146",
        "enabled": "1",
        "code3l": "SLE",
        "code2l": "SL",
        "name": "Sierra Leone",
        "name_official": "Republic of Sierra Leone",
        "flag_32": "SL-32.png",
        "flag_128": "SL-128.png",
        "latitude": "8.45575589",
        "longitude": "-11.93368759",
        "zoom": "8"
    },
    {
        "id": "147",
        "enabled": "1",
        "code3l": "SGP",
        "code2l": "SG",
        "name": "Singapore",
        "name_official": "Republic of Singapore",
        "flag_32": "SG-32.png",
        "flag_128": "SG-128.png",
        "latitude": "1.33873261",
        "longitude": "103.83323559",
        "zoom": "11"
    },
    {
        "id": "238",
        "enabled": "1",
        "code3l": "SXM",
        "code2l": "SX",
        "name": "Sint Maarten",
        "name_official": "Sint Maarten",
        "flag_32": "SX-32.png",
        "flag_128": "SX-128.png",
        "latitude": "18.04433885",
        "longitude": "-63.05616320",
        "zoom": "12"
    },
    {
        "id": "148",
        "enabled": "1",
        "code3l": "SVK",
        "code2l": "SK",
        "name": "Slovakia",
        "name_official": "Slovak Republic",
        "flag_32": "SK-32.png",
        "flag_128": "SK-128.png",
        "latitude": "48.66923253",
        "longitude": "19.75396564",
        "zoom": "7"
    },
    {
        "id": "149",
        "enabled": "1",
        "code3l": "SVN",
        "code2l": "SI",
        "name": "Slovenia",
        "name_official": "Republic of Slovenia",
        "flag_32": "SI-32.png",
        "flag_128": "SI-128.png",
        "latitude": "46.14315048",
        "longitude": "14.99546300",
        "zoom": "8"
    },
    {
        "id": "150",
        "enabled": "1",
        "code3l": "SLB",
        "code2l": "SB",
        "name": "Solomon Islands",
        "name_official": "Solomon Islands",
        "flag_32": "SB-32.png",
        "flag_128": "SB-128.png",
        "latitude": "-9.64554280",
        "longitude": "160.15619400",
        "zoom": "10"
    },
    {
        "id": "151",
        "enabled": "1",
        "code3l": "SOM",
        "code2l": "SO",
        "name": "Somalia",
        "name_official": "Somali Republic",
        "flag_32": "SO-32.png",
        "flag_128": "SO-128.png",
        "latitude": "2.87224619",
        "longitude": "45.27676444",
        "zoom": "7"
    },
    {
        "id": "152",
        "enabled": "1",
        "code3l": "ZAF",
        "code2l": "ZA",
        "name": "South Africa",
        "name_official": "Republic of South Africa",
        "flag_32": "ZA-32.png",
        "flag_128": "ZA-128.png",
        "latitude": "-27.17706863",
        "longitude": "24.50856092",
        "zoom": "5"
    },
    {
        "id": "215",
        "enabled": "1",
        "code3l": "SGS",
        "code2l": "GS",
        "name": "South Georgia and the South Sandwich Islands",
        "name_official": "South Georgia and the South Sandwich Islands",
        "flag_32": "GS-32.png",
        "flag_128": "GS-128.png",
        "latitude": "-54.38130284",
        "longitude": "-36.67305304",
        "zoom": "9"
    },
    {
        "id": "189",
        "enabled": "1",
        "code3l": "KOR",
        "code2l": "KR",
        "name": "South Korea",
        "name_official": "Republic of Korea",
        "flag_32": "KR-32.png",
        "flag_128": "KR-128.png",
        "latitude": "36.56344139",
        "longitude": "127.51424646",
        "zoom": "7"
    },
    {
        "id": "250",
        "enabled": "1",
        "code3l": "SSD",
        "code2l": "SS",
        "name": "South Sudan",
        "name_official": "Republic of South Sudan",
        "flag_32": "SS-32.png",
        "flag_128": "SS-128.png",
        "latitude": "7.91320803",
        "longitude": "30.15342434",
        "zoom": "6"
    },
    {
        "id": "153",
        "enabled": "1",
        "code3l": "ESP",
        "code2l": "ES",
        "name": "Spain",
        "name_official": "Kingdom of Spain",
        "flag_32": "ES-32.png",
        "flag_128": "ES-128.png",
        "latitude": "39.87299401",
        "longitude": "-3.67089492",
        "zoom": "6"
    },
    {
        "id": "154",
        "enabled": "1",
        "code3l": "LKA",
        "code2l": "LK",
        "name": "Sri Lanka",
        "name_official": "Democratic Socialist Republic of Sri  Lanka",
        "flag_32": "LK-32.png",
        "flag_128": "LK-128.png",
        "latitude": "7.61264985",
        "longitude": "80.83772497",
        "zoom": "7"
    },
    {
        "id": "155",
        "enabled": "1",
        "code3l": "SDN",
        "code2l": "SD",
        "name": "Sudan",
        "name_official": "Republic of the Sudan",
        "flag_32": "SD-32.png",
        "flag_128": "SD-128.png",
        "latitude": "15.96646839",
        "longitude": "30.37145459",
        "zoom": "5"
    },
    {
        "id": "156",
        "enabled": "1",
        "code3l": "SUR",
        "code2l": "SR",
        "name": "Suriname",
        "name_official": "Republic of Suriname",
        "flag_32": "SR-32.png",
        "flag_128": "SR-128.png",
        "latitude": "4.26470865",
        "longitude": "-55.93988238",
        "zoom": "7"
    },
    {
        "id": "237",
        "enabled": "1",
        "code3l": "SJM",
        "code2l": "SJ",
        "name": "Svalbard and Jan Mayen",
        "name_official": "Svalbard and Jan Mayen",
        "flag_32": "SJ-32.png",
        "flag_128": "SJ-128.png",
        "latitude": "77.92215764",
        "longitude": "18.99010622",
        "zoom": "4"
    },
    {
        "id": "157",
        "enabled": "1",
        "code3l": "SWZ",
        "code2l": "SZ",
        "name": "Swaziland",
        "name_official": "Kingdom of Swaziland",
        "flag_32": "SZ-32.png",
        "flag_128": "SZ-128.png",
        "latitude": "-26.53892570",
        "longitude": "31.47960891",
        "zoom": "9"
    },
    {
        "id": "158",
        "enabled": "1",
        "code3l": "SWE",
        "code2l": "SE",
        "name": "Sweden",
        "name_official": "Kingdom of Sweden",
        "flag_32": "SE-32.png",
        "flag_128": "SE-128.png",
        "latitude": "61.42370427",
        "longitude": "16.73188991",
        "zoom": "4"
    },
    {
        "id": "159",
        "enabled": "1",
        "code3l": "CHE",
        "code2l": "CH",
        "name": "Switzerland",
        "name_official": "Swiss Confederation",
        "flag_32": "CH-32.png",
        "flag_128": "CH-128.png",
        "latitude": "46.81010721",
        "longitude": "8.22751200",
        "zoom": "8"
    },
    {
        "id": "160",
        "enabled": "1",
        "code3l": "SYR",
        "code2l": "SY",
        "name": "Syria",
        "name_official": "Syrian Arab Republic",
        "flag_32": "SY-32.png",
        "flag_128": "SY-128.png",
        "latitude": "34.71097430",
        "longitude": "38.66723516",
        "zoom": "6"
    },
    {
        "id": "242",
        "enabled": "1",
        "code3l": "TWN",
        "code2l": "TW",
        "name": "Taiwan",
        "name_official": "Republic of China",
        "flag_32": "TW-32.png",
        "flag_128": "TW-128.png",
        "latitude": "23.71891402",
        "longitude": "121.10884043",
        "zoom": "7"
    },
    {
        "id": "161",
        "enabled": "1",
        "code3l": "TJK",
        "code2l": "TJ",
        "name": "Tajikistan",
        "name_official": "Republic of Tajikistan",
        "flag_32": "TJ-32.png",
        "flag_128": "TJ-128.png",
        "latitude": "38.68075124",
        "longitude": "71.23215769",
        "zoom": "7"
    },
    {
        "id": "193",
        "enabled": "1",
        "code3l": "TZA",
        "code2l": "TZ",
        "name": "Tanzania",
        "name_official": "United Republic of Tanzania",
        "flag_32": "TZ-32.png",
        "flag_128": "TZ-128.png",
        "latitude": "-6.37551085",
        "longitude": "34.85587302",
        "zoom": "6"
    },
    {
        "id": "162",
        "enabled": "1",
        "code3l": "THA",
        "code2l": "TH",
        "name": "Thailand",
        "name_official": "Kingdom of Thailand",
        "flag_32": "TH-32.png",
        "flag_128": "TH-128.png",
        "latitude": "14.60009810",
        "longitude": "101.38805881",
        "zoom": "5"
    },
    {
        "id": "60",
        "enabled": "1",
        "code3l": "GMB",
        "code2l": "GM",
        "name": "The Gambia",
        "name_official": "Republic of The Gambia",
        "flag_32": "GM-32.png",
        "flag_128": "GM-128.png",
        "latitude": "13.15921146",
        "longitude": "-15.35956748",
        "zoom": "8"
    },
    {
        "id": "163",
        "enabled": "1",
        "code3l": "TLS",
        "code2l": "TL",
        "name": "Timor-Leste",
        "name_official": "Democratic Republic of Timor-Leste",
        "flag_32": "TL-32.png",
        "flag_128": "TL-128.png",
        "latitude": "-8.88926365",
        "longitude": "125.99671404",
        "zoom": "9"
    },
    {
        "id": "164",
        "enabled": "1",
        "code3l": "TGO",
        "code2l": "TG",
        "name": "Togo",
        "name_official": "Togolese Republic",
        "flag_32": "TG-32.png",
        "flag_128": "TG-128.png",
        "latitude": "8.68089206",
        "longitude": "0.86049757",
        "zoom": "7"
    },
    {
        "id": "241",
        "enabled": "1",
        "code3l": "TKL",
        "code2l": "TK",
        "name": "Tokelau",
        "name_official": "Tokelau",
        "flag_32": "TK-32.png",
        "flag_128": "TK-128.png",
        "latitude": "-9.16682644",
        "longitude": "-171.83981693",
        "zoom": "10"
    },
    {
        "id": "165",
        "enabled": "1",
        "code3l": "TON",
        "code2l": "TO",
        "name": "Tonga",
        "name_official": "Kingdom of Tonga",
        "flag_32": "TO-32.png",
        "flag_128": "TO-128.png",
        "latitude": "-21.17890075",
        "longitude": "-175.19824200",
        "zoom": "11"
    },
    {
        "id": "166",
        "enabled": "1",
        "code3l": "TTO",
        "code2l": "TT",
        "name": "Trinidad and Tobago",
        "name_official": "Republic of Trinidad and Tobago",
        "flag_32": "TT-32.png",
        "flag_128": "TT-128.png",
        "latitude": "10.43241863",
        "longitude": "-61.22250300",
        "zoom": "10"
    },
    {
        "id": "167",
        "enabled": "1",
        "code3l": "TUN",
        "code2l": "TN",
        "name": "Tunisia",
        "name_official": "Republic of Tunisia",
        "flag_32": "TN-32.png",
        "flag_128": "TN-128.png",
        "latitude": "33.88431940",
        "longitude": "9.71878341",
        "zoom": "6"
    },
    {
        "id": "168",
        "enabled": "1",
        "code3l": "TUR",
        "code2l": "TR",
        "name": "Turkey",
        "name_official": "Republic of Turkey",
        "flag_32": "TR-32.png",
        "flag_128": "TR-128.png",
        "latitude": "38.27069555",
        "longitude": "36.28703317",
        "zoom": "5"
    },
    {
        "id": "169",
        "enabled": "1",
        "code3l": "TKM",
        "code2l": "TM",
        "name": "Turkmenistan",
        "name_official": "Turkmenistan",
        "flag_32": "TM-32.png",
        "flag_128": "TM-128.png",
        "latitude": "38.94915421",
        "longitude": "59.06190323",
        "zoom": "6"
    },
    {
        "id": "239",
        "enabled": "1",
        "code3l": "TCA",
        "code2l": "TC",
        "name": "Turks and Caicos Islands",
        "name_official": "Turks and Caicos Islands",
        "flag_32": "TC-32.png",
        "flag_128": "TC-128.png",
        "latitude": "21.72816866",
        "longitude": "-71.79654471",
        "zoom": "9"
    },
    {
        "id": "170",
        "enabled": "1",
        "code3l": "TUV",
        "code2l": "TV",
        "name": "Tuvalu",
        "name_official": "Tuvalu",
        "flag_32": "TV-32.png",
        "flag_128": "TV-128.png",
        "latitude": "-8.45968122",
        "longitude": "179.13310944",
        "zoom": "12"
    },
    {
        "id": "171",
        "enabled": "1",
        "code3l": "UGA",
        "code2l": "UG",
        "name": "Uganda",
        "name_official": "Republic of Uganda",
        "flag_32": "UG-32.png",
        "flag_128": "UG-128.png",
        "latitude": "1.54760620",
        "longitude": "32.44409759",
        "zoom": "7"
    },
    {
        "id": "172",
        "enabled": "1",
        "code3l": "UKR",
        "code2l": "UA",
        "name": "Ukraine",
        "name_official": "Ukraine",
        "flag_32": "UA-32.png",
        "flag_128": "UA-128.png",
        "latitude": "48.89358596",
        "longitude": "31.10516920",
        "zoom": "6"
    },
    {
        "id": "173",
        "enabled": "1",
        "code3l": "ARE",
        "code2l": "AE",
        "name": "United Arab Emirates",
        "name_official": "United Arab Emirates",
        "flag_32": "AE-32.png",
        "flag_128": "AE-128.png",
        "latitude": "24.64324405",
        "longitude": "53.62261227",
        "zoom": "7"
    },
    {
        "id": "186",
        "enabled": "1",
        "code3l": "GBR",
        "code2l": "GB",
        "name": "United Kingdom",
        "name_official": "United Kingdom of Great Britain and Northern Ireland",
        "flag_32": "GB-32.png",
        "flag_128": "GB-128.png",
        "latitude": "53.36540813",
        "longitude": "-2.72184767",
        "zoom": "6"
    },
    {
        "id": "244",
        "enabled": "1",
        "code3l": "USA",
        "code2l": "US",
        "name": "United States",
        "name_official": "United States of America",
        "flag_32": "US-32.png",
        "flag_128": "US-128.png",
        "latitude": "37.66895362",
        "longitude": "-102.39256450",
        "zoom": "4"
    },
    {
        "id": "243",
        "enabled": "1",
        "code3l": "UMI",
        "code2l": "UM",
        "name": "United States Minor Outlying Islands",
        "name_official": "United States Minor Outlying Islands",
        "flag_32": "UM-32.png",
        "flag_128": "UM-128.png",
        "latitude": "19.46305694",
        "longitude": "177.98631092",
        "zoom": "5"
    },
    {
        "id": "247",
        "enabled": "1",
        "code3l": "VIR",
        "code2l": "VI",
        "name": "United States Virgin Islands",
        "name_official": "Virgin Islands of the United States",
        "flag_32": "VI-32.png",
        "flag_128": "VI-128.png",
        "latitude": "18.01000938",
        "longitude": "-64.77411410",
        "zoom": "9"
    },
    {
        "id": "174",
        "enabled": "1",
        "code3l": "URY",
        "code2l": "UY",
        "name": "Uruguay",
        "name_official": "Eastern Republic of Uruguay",
        "flag_32": "UY-32.png",
        "flag_128": "UY-128.png",
        "latitude": "-32.49342987",
        "longitude": "-55.76583300",
        "zoom": "7"
    },
    {
        "id": "175",
        "enabled": "1",
        "code3l": "UZB",
        "code2l": "UZ",
        "name": "Uzbekistan",
        "name_official": "Republic of Uzbekistan",
        "flag_32": "UZ-32.png",
        "flag_128": "UZ-128.png",
        "latitude": "41.30829147",
        "longitude": "62.62970960",
        "zoom": "6"
    },
    {
        "id": "176",
        "enabled": "1",
        "code3l": "VUT",
        "code2l": "VU",
        "name": "Vanuatu",
        "name_official": "Republic of Vanuatu",
        "flag_32": "VU-32.png",
        "flag_128": "VU-128.png",
        "latitude": "-15.37256614",
        "longitude": "166.95916000",
        "zoom": "8"
    },
    {
        "id": "245",
        "enabled": "1",
        "code3l": "VAT",
        "code2l": "VA",
        "name": "Vatican City State",
        "name_official": "State of the Vatican City",
        "flag_32": "VA-32.png",
        "flag_128": "VA-128.png",
        "latitude": "41.90377810",
        "longitude": "12.45340142",
        "zoom": "16"
    },
    {
        "id": "194",
        "enabled": "1",
        "code3l": "VEN",
        "code2l": "VE",
        "name": "Venezuela",
        "name_official": "Bolivarian Republic of Venezuela",
        "flag_32": "VE-32.png",
        "flag_128": "VE-128.png",
        "latitude": "5.98477766",
        "longitude": "-65.94152264",
        "zoom": "6"
    },
    {
        "id": "177",
        "enabled": "1",
        "code3l": "VNM",
        "code2l": "VN",
        "name": "Viet Nam",
        "name_official": "Socialist Republic of Viet Nam",
        "flag_32": "VN-32.png",
        "flag_128": "VN-128.png",
        "latitude": "17.19931699",
        "longitude": "107.14012804",
        "zoom": "5"
    },
    {
        "id": "246",
        "enabled": "1",
        "code3l": "VGB",
        "code2l": "VG",
        "name": "Virgin Islands",
        "name_official": "British Virgin Islands",
        "flag_32": "VG-32.png",
        "flag_128": "VG-128.png",
        "latitude": "17.67004187",
        "longitude": "-64.77411010",
        "zoom": "10"
    },
    {
        "id": "248",
        "enabled": "1",
        "code3l": "WLF",
        "code2l": "WF",
        "name": "Wallis and Futuna",
        "name_official": "Territory of the Wallis and Futuna Islands",
        "flag_32": "WF-32.png",
        "flag_128": "WF-128.png",
        "latitude": "-14.29378486",
        "longitude": "-178.11649800",
        "zoom": "12"
    },
    {
        "id": "207",
        "enabled": "1",
        "code3l": "ESH",
        "code2l": "EH",
        "name": "Western Sahara",
        "name_official": "Western Sahara",
        "flag_32": "EH-32.png",
        "flag_128": "EH-128.png",
        "latitude": "24.79324356",
        "longitude": "-13.67683563",
        "zoom": "6"
    },
    {
        "id": "178",
        "enabled": "1",
        "code3l": "YEM",
        "code2l": "YE",
        "name": "Yemen",
        "name_official": "Republic of Yemen",
        "flag_32": "YE-32.png",
        "flag_128": "YE-128.png",
        "latitude": "15.60865453",
        "longitude": "47.60453676",
        "zoom": "6"
    },
    {
        "id": "179",
        "enabled": "1",
        "code3l": "ZMB",
        "code2l": "ZM",
        "name": "Zambia",
        "name_official": "Republic of Zambia",
        "flag_32": "ZM-32.png",
        "flag_128": "ZM-128.png",
        "latitude": "-13.01812188",
        "longitude": "28.33274444",
        "zoom": "6"
    },
    {
        "id": "180",
        "enabled": "1",
        "code3l": "ZWE",
        "code2l": "ZW",
        "name": "Zimbabwe",
        "name_official": "Republic of Zimbabwe",
        "flag_32": "ZW-32.png",
        "flag_128": "ZW-128.png",
        "latitude": "-19.00784952",
        "longitude": "30.18758584",
        "zoom": "6"
    }
];