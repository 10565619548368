import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material';
import { ConfirmDialogService } from 'src/app/providers/modals/confirm-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class CompetitionsService {

  competitionsDB: AngularFirestoreCollection<any> = this.afs.collection('competitions');
  competitionsEntriesDB: AngularFirestoreCollection<any> = this.afs.collection('competitionsEntries');
  artworkDB: AngularFirestoreCollection<any> = this.afs.collection('artwork');

  constructor(
    public afAuth: AngularFireAuth,
    public afd: AngularFireDatabase,
    private afs: AngularFirestore,
    public snackBar: MatSnackBar,
    public confirmDialog: ConfirmDialogService
    ) {}

    getCompetitions() {
      return this.competitionsDB;
    }

    addCompetition(data) {
      return this.competitionsDB.add(data).then(res => {
        res.update({ id: res.id }).then(response => {
          this.snackBar.open('Competition added', 'CLOSE', {
            duration: 3000,
          });
        });
      })
    }
  
    updateCompetition(data) {
      return this.competitionsDB.doc(data.id).update(data).then(response => {
        this.snackBar.open('Competition updated', 'CLOSE', {
          duration: 3000,
        });
      });
    }

    // WORK

    addWork(data) {
      return this.artworkDB.add(data).then(res => {
        res.update({ id: res.id }).then(response => {
          this.snackBar.open('Your work was added', 'CLOSE', {
            duration: 5000,
          });
        });
      })
    }


    updateWork(data) {
      console.log('data', data);
      return this.artworkDB.doc(data.id).update(data).then(response => {
        this.snackBar.open('Your work has updated', 'CLOSE', {
          duration: 3000,
        });
      });
    }

    deleteWork(type, data) {
      this.confirmDialog.openConfirmDialog(type, data).subscribe(res => {
        if(res) {
          return this.artworkDB.doc(data.id).delete().then(a => {
            this.snackBar.open(type + ' deleted', 'CLOSE', {
              duration: 2000,
            });
          });
        }
      })
    }

    getUserWork(id) {
      let userWork = this.afs.collection('artwork', ref => ref.where('userId', '==', id).orderBy('dateAdded', 'desc'));
      return userWork;
    }

    // ENTRIES

    enterCompetition(data) {
      return this.competitionsEntriesDB.add(data).then(res => {
        res.update({ id: res.id }).then(response => {
          this.snackBar.open('Your entry was accepted', 'CLOSE', {
            duration: 5000,
          });
        });
      })
    }

    

}
