import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EnterCompetitionComponent } from '../enter-competition/enter-competition.component';
import { UsersService } from 'src/app/api/users/users.service';
import { CompetitionsService } from 'src/app/api/competitions/competitions.service';

@Component({
  selector: 'app-add-work',
  templateUrl: './add-work.component.html',
  styleUrls: ['./add-work.component.scss']
})
export class AddWorkComponent implements OnInit {

  form: FormGroup;
  userData: any;
  buttonLabel: any;
  artworkDescription: string = '';
  workData: any = {
    artworkDescription: '',
    imageUrl: ''
  };;
  imageUrl: any;
  
  constructor(
    public dialogRef: MatDialogRef<EnterCompetitionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userProvider: UsersService,
    private competitionProviders: CompetitionsService,
  ) {
      console.log(this.data)
  }

  ngOnInit() {
    this.buttonLabel = 'Upload artwork'
    this.artworkDescription = this.data.workData ? this.data.workData.artworkDescription : '';
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onImageUploaded(url) {
    this.workData.imageUrl = url;
  }

  addWork() {
    this.workData.userId = this.data.userData.id;
    this.workData.dateAdded = Date.now();
    this.workData.artworkDescription = this.artworkDescription;

    if(this.data.workData) {
      console.log(this.data.workData);
      this.workData.id = this.data.workData.id;
      this.workData.imageUrl = this.workData.imageUrl ? this.workData.imageUrl : this.data.workData.imageUrl;
      this.competitionProviders.updateWork(this.workData);
    }
    else {
      this.competitionProviders.addWork(this.workData);
    }

    this.dialogRef.close();
  }

}
